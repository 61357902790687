/**
 * Analytics Constants
 * @module
 * @author paul.graffam@isobar.com
 */

export default {
  SESSION_STORAGE_KEY: 'ANALYTICS_SESSION_STORAGE_KEY',

  // Types
  DRAWER: 'drawer',
  FILTER: 'filter',
  MODAL: 'modal',
  PAGE: 'page',
  MESSAGE: 'Message',
  SEARCH_RESULTS: 'Search Results',
  TOGGLE: 'toggle',

  // Other types?
  LOCATION: 'location type',
  VEHICLE: 'vehicle type',
  CLOSED: 'closed location',
  DEFAULT: 'default location',
  PICKUP: 'pick up location',
  RETURN: 'return location',
  LOGIN: 'log in',
  INVALID_DATES: 'invalid_dates',
  CARS: 'cars',
  PROMOTION: 'promotion',
  FAILED: 'Failed',
  PASSED: 'Passed',
  COMPLETE: 'Complete',
  IN_COMPLETE: 'InComplete',
  ERROR: 'Error',
  SUB_PAGE: 'subpage',

  // Keys
  RESERVATION: 'reservation',
  TIME: 'time',
  DATE: 'date',
  FREQUENT_TRAVELER_RECOVERY_FEE: 'frequent traveler recovery fee',
  FE_FORM_ERROR: (inputName) => `FE_${inputName?.toUpperCase()}`,
  GENERIC_FE_FORM_ERROR: 'FE_ERROR',
  // Resflow Interaction Keys
  CAR_CLASS_DETAILS: 'car class details',
  CAR_CLASS: 'car class',
  TAXES_AND_FEES: 'taxes and fees',
  PREPAY_POLICY: 'pre-pay policy',
  LIMITED_INVENTORY: 'limited inventory',
  RECOMMENDED: 'recommended',
  LIMITED_INVENTORY_VEHICLE: 'limited inventory vehicle',
  PRICE_AND_PAYMENT_DETAILS: 'price and payment details',
  CANCEL_RESERVATION_KEY: 'cancel reservation',
  MODIFY_RESERVATION_KEY: 'modify reservation',
  BUNDLES_DETAILS_MODAL: 'bundle details {0} modal',
  PARTNER_REWARDS: 'partner rewards',
  ENROLL: 'enroll',
  LOGIN_SUCCESSFUL: 'successful',
  PROFILE: 'profile',
  UNSUBSCRIBE_SUCCESSFUL: 'unsubscribe successful',
  FE_PAST_TRIPS_LOOKUP: 'FE_PAST_TRIPS_LOOKUP',
  NOT_FOUND: 'not found',
  SOLD_OUT: 'sold out',
  CONTRACT: 'contract',
  COUPON: 'coupon',
  SORT_AND_FILTER: 'sort and filter',
  SORT_BY: 'Sort By',
  CUSTOM_PATH: 'custom path check in',
  CHECK_IN: 'check in',
  RETAIL: 'retail',
  CAR_TYPE: 'car type',
  AVAILABILITY: 'Class Availablility',
  PREFERRED_CLASS_COUNT: 'Preferred Class Count',
  CHECKBOX: 'check box',
  DATE_TIME: 'date time',
  VEHICLE_PAGE: 'vehicle',
  CURRENCY: 'currency',
  PROFILE_LOOKUP: 'profile lookup',
  PROFILE_NOT_FOUND: 'profile not found',
  PROFILE_FOUND: 'profile found',
  CHECK_IN_COMPLETE: 'check-in complete',
  EDIT_CONTACT_INFORMATION: 'edit contact information',
  EDIT_DRIVER_LICENSE: 'edit drivers license',
  EMAIL_SIGNUP_MODAL: 'email sign up',
  ADD_TO_MOBILE_WALLET: 'add to mobile wallet',
  FORGOT_PASSWORD: 'forgot password',
  FORGOT_USERNAME: 'forgot username',
  RESFLOW_LOGOUT_MODAL: 'resflow logout modal',
  SKIP_ANALYTICS_FOR_SAVED_RESERVATION_SESSION_LDT_VALUES: 'skipAnalyticsForSavedReservationSessionLDTValues',
  SKIP_ANALYTICS_FOR_DATE_TIME_DRAWER_ON_UNAVAILABLE_LOCATION_MODAL_SUBMIT:
    'skipAnalyticsForDateTimeDrawerOnUnavailableLocationModalSubmit',
  PHONE: 'phone',
  CONFIRM_MODIFY: 'confirm/modify',

  // UI
  UI_CHECKBOX: 'checkbox',
  UI_LINK: 'link',
  UI_BUTTON: 'button',
  UI_DROPDOWN: 'dropdown',
  UI_TOOLTIP: 'tooltip',
  UI_BANNER: 'banner',
  UI_ALERT: 'alert',
  UI_HEADER: 'header',
  UI_DROPDOWN_SELECT: 'dropdown select',

  // Actions
  ADJUST: 'adjust',
  BACK: 'back',
  GO_BACK: 'go back',
  CALL: 'call',
  OPEN: 'open',
  CLOSE: 'close',
  NEXT: 'next',
  PREVIOUS: 'previous',
  CHANGE_LOCATION: 'change_location',
  SELECT: 'select',
  SELECTED: 'selected',
  SKIP: 'skip',
  CONTINUE: 'continue',
  CONFIRM: 'confirm',
  PRINT: 'print',
  SHARE: 'share',
  MODIFY: 'modify',
  CANCEL: 'cancel',
  DONE: 'done',
  DECLINE: 'decline',
  ACCEPT: 'accept',
  SAVE: 'save',
  SUBMIT: 'submit',
  DISCARD: 'discard',
  SIGN_IN: 'sign in',
  CHANGE_DATE_TIME: 'change date time',
  EDIT_DATES: 'edit_dates',
  RETURN_HOME: 'return_home',
  ADJUST_DATE_TIME: 'adjust date time',
  SEE_NEARBY_LOCATIONS: 'see nearby locations',
  VIEW_PARTNER_LOCATIONS: 'view partner locations',
  APPLY: 'apply',
  APPLY_FILTER: 'apply filter',
  CLEAR_ALL: 'clear all',
  SELECT_ALL: 'select all',
  FILTER_BY_VEHICLE_CLASS: 'Filter by Vehicle Class',
  UPDATE_FLIGHT_INFORMATION: 'update flight information',
  FLIGHT_INFO_CONFIRMED: 'flight info confirmed',
  CHAT_WITH_US: 'chat with us',
  NO_THANKS_SIGNUP_MODAL: 'no thanks',
  SIGN_UP: 'sign up',
  SIGN_OUT: 'sign out',
  CALL_CUSTOMER_SERVICE: 'call customer service',
  VIEW_PAGE_IN_FRENCH: 'view page in french',
  ANNULER: 'annuler',
  CONTINUER: 'continuer',

  // Interaction Values
  NO_KEEP_RESERVATION: 'no keep reservation',
  KEEP_RESERVATION: 'keep reservation',
  CANCEL_RESERVATION: 'yes cancel',
  USE_CURRENT_LOCATION: 'current location',
  NO_THANKS: 'no, thanks',
  LOGIN_ORIGINAL: 'original',
  LOGIN_REMEMBER_ME: 'remember me',
  ADD: 'add',
  EDIT: 'edit',
  UPDATE: 'update',
  VIEW_MAP: 'view map',
  VIEW_DETAILS: 'view details',
  VIEW_RECEIPT: 'view receipt',
  ADD_DRIVER: 'add driver',
  ADD_LATER: 'add later',
  ACTIVATE: 'activate',
  REMOVE: 'remove',
  STAY: 'stay',
  INTERACTION_OPEN: 'open',
  FILTER_OPEN: 'filter Open',
  NO_SELECTED_CLASS: 'No Selected Class Available',
  PREFFERRED_CLASS_VEHICLES_RETURNED: 'of preffered class vehicles returned',
  NEVERMIND: 'nevermind',
  ASSOCIATE: 'Associate',
  ASSOCIATE_ALAMO_ACCOUNT: 'associate your alamo account',
  ACCOUNT_ASSOCIATED: 'account associated',
  EXIT_AND_DISCARD: 'exit and discard',
  PICKUP_TICKET: 'pickup ticket',
  TRAVEL_ADVISORY: 'travel advisory',
  MODIFY_RETURN: 'modify my return',
  CONFIRM_RETURN: 'confirm my return',
  CHANGE_RETURN_LOCATION: 'change return location',
  FIND_RENTAL: 'find rental',
  SEND_ANOTHER_EMAIL: 'send me another email',
  FIND_CURRENT_RENTAL: 'find a current rental',
  FIND_UPCOMING_RENTAL: 'find an upcoming rental',
  SEND_ANOTHER_TEXT_MESSAGE: 'send me another text message',
  TRY_ANOTHER_LOOKUP: 'try another lookup method',
  BUTTON_TEXT_GOT_IT: 'got it',

  // Other Values
  GENERIC_FE_FORM_ERROR_VALUE: 'FE Error',

  // Placements
  LOCATION_ID: (id) => `location_${id}`,
  LOCATION_FILTER: 'location_filter',
  CLOSED_LOCATION: 'closed_location',
  NO_LOCATIONS: 'no_locations_available',
  LOCATION_DETAILS: 'location_details',
  HOURS_OF_OPERATION: 'hours_of_operation',
  CONFIRMATION: 'confirmation',
  CANCELLATION: 'cancellation',
  KEY_RENTAL_FACTS: 'key_rental_facts',
  RENTAL_POLICIES: 'rental_policies',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  PROGRESS_BAR: 'progress_bar',
  PREPAYMENT_TERMS_CONDITIONS: 'prepayment_terms_conditions',
  MY_TRIPS: 'my_trips',
  PAST_TRIPS: 'past_trips',
  UPCOMING_TRIPS: 'upcoming_trips',
  CURRENT_TRIPS: 'current_trips',
  CURRENT_RENTAL: 'current_rental',
  TRIP_DETAILS: 'trip_details',
  FIND_RESERVATION: 'find_reservation',
  SEARCH: 'search',
  NEED_TO_MODIFY: 'need_to_modify',
  VIEW_MODIFY_CANCEL: 'view_modify_cancel',
  EXTRAS: 'extras',
  LOCATIONS: 'locations',
  STORE_FINDER: 'storefinder',
  RES_WIDGET: 'res_widget',
  LOCATION_PAGE: 'location',
  MAP: 'map',
  RW: 'rw',
  REVIEW: 'review',

  // Action Types used in Redux middleware
  // Interactions
  ACTION_FILTER: 'SET_FILTERS_UI_VALUES',
  ACTION_COMPOSE_FILTERS: 'COMPOSE_FILTERS_UI_SHAPE',
  ACTION_OPEN_MODAL: '@@modal/OPEN_MODAL',
  ACTION_CLOSE_MODAL: '@@modal/CLOSE_MODAL',
  ACTION_CLEAR_MODAL_QUEUE: '@@modal/CLEAR_MODAL_QUEUE',
  // Errors
  ACTION_REQUEST_ERROR: 'REQUEST_ERROR',
  // Merges
  ACTION_REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  ACTION_UPDATE_SESSION: 'UPDATE_SESSION',
  // GMI
  SET_GMI_STATE: 'SET_GMI_STATE',
  GMI_RETURN_TIME_NODE_NAME: 'return_time',
  GMI_PICKUP_TIME_NODE_NAME: 'pickup_time',
  GMI_RETURN_DATE_NODE_NAME: 'return_date',
  GMI_PICKUP_DATE_NODE_NAME: 'pickup_date',

  FE_PHONE: 'FE_PHONE',
  PHONE_INVALID: 'Phone Number is invalid',

  RESFLOW_PATHS_CONFIG: ['/car_select', '/extras', 'confirmation', 'confirmation-modify'],

  // Confirm/Modify
  RENTALFLOW_PATH: 'modify-rental',
  DATES_AND_TIMES_EDIT: 'dates & times edit',
  DROPDOWN_MODIFY_RETURN_TIME: 'modify return time',
  DROPDOWN_MODIFY_RETURN_DATE: 'modify return date',

  // filter
  FILTERS_TRANSMISSION: 'filters.TRANSMISSION.filter_code',
  ALL_FILTER: 'All',

  // Expiration Modal
  PAYMENT_METHOD_EXPIRING_SOON: 'payment method expiring soon',
  CHOOSE_ANOTHER_DATE: 'choose another date',
  CALL_FOR_ASSISTANCE: 'call for assistance',

  DISCOUNT_CODES: 'Discount Codes',
  DISCOUNT_CODE_CONTRACT_ID: 'Discount Code or Contract ID',
};
